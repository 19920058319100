import React from 'react';
import { BoxWrapper, FormRow } from '../../../../components';

import Properties from './Properties';
import stylesGlobal from '../../../../globalStyles';
import ProductInfoLocales from './ProductInfoLocales';
import Category from './Category';
import Vendor from './Vendor';

const RozetkaInfo = ({ categoryId }) => {
  return (
    <FormRow>
      <FormRow.Left>
        <BoxWrapper style={stylesGlobal.form}>
          <ProductInfoLocales />
        </BoxWrapper>
        <BoxWrapper style={stylesGlobal.form}>
          <Vendor />
        </BoxWrapper>
        <BoxWrapper style={stylesGlobal.form}>
          <Category />
        </BoxWrapper>
        <BoxWrapper style={stylesGlobal.form}>
          <Properties categoryId={categoryId} />
        </BoxWrapper>
      </FormRow.Left>
      <FormRow.Right></FormRow.Right>
    </FormRow>
  );
};

export default RozetkaInfo;
