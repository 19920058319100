import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Flex } from 'native-base';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  BoxWrapper,
  FormRow,
  FormWrapper,
  Layout,
  RequestHandler,
  TabNavigation,
  TextFieldHookFrom,
  Typography,
} from '../../../components';
import CurrencyIdSelect from '../../../components/CurrencyIdSelect/CurrencyIdSelect';
import { IS_DESKTOP_SCREEN } from '../../../constants';
import stylesGlobal from '../../../globalStyles';
import {
  useAddProductMutation,
  useCreateRozetkaProduct,
  useGetCurrenciesQuery,
  useGetProduct,
  useGetRozetkaProduct,
  useHasScopesQuery,
  useParams,
  useUpdateRozetkaProduct,
} from '../../../hooks';
import { addProductSchema, addRozetkaProductSchema } from '../../../services/validation';
import { getVolumeWeight } from '../../../utils';
import { Availability } from './Availability/Availability';
import Barcodes from './Barcodes/Barcodes';
import PriceTags from './PriceTags/PriceTags';
import ProductLocales from './ProductLocales/ProductLocales';
import RozetkaInfo from './RozetkaInfo/RozetkaInfo';
import UploadImage from './UploadImage/UploadImage';
import UploadImageMobile from './UploadImage/UploadImageMobile/UploadImageMobile';

const Product = () => {
  const { data, ...productQueryProps } = useGetProduct();
  const { data: rozetkaData, ...rozetkaProductQueryProps } = useGetRozetkaProduct();

  const { data: hasScopesData } = useHasScopesQuery(['Purchase:Read']);
  const { data: currenciesData, ...currenciesQueryProps } = useGetCurrenciesQuery();
  const [addProduct, { loading }] = useAddProductMutation();
  const [addRozetkaProduct, { loading: createRozetkaProductLoading }] =
    useCreateRozetkaProduct();
  const [updateRozetkaProduct, { loading: updateRozetkaProductLoading }] =
    useUpdateRozetkaProduct();
  const [currentIndex, setCurrentIndex] = useState(0);

  const params = useParams();

  const hasScope = hasScopesData?.hasScopes[0]?.isAllowed;
  const methods = useForm({
    resolver: yupResolver(
      currentIndex === 0 ? addProductSchema : addRozetkaProductSchema,
    ),
    reValidateMode: 'onBlur',
    defaultValues: {
      name: data?.product?.name || '',
      sku: data?.product?.sku || '',
      weight: data?.product?.weight?.toString() || '',
      width: data?.product?.width?.toString() || '',
      height: data?.product?.height?.toString() || '',
      length: data?.product?.length?.toString() || '',
      price: data?.product?.price?.toString() || '',
      priceCurrencyId: data?.product?.priceCurrencyId?.toString() || '',
      MSRprice: data?.product?.MSRprice?.toString() || '',
      MSRpriceCurrencyId: data?.product?.MSRpriceCurrencyId?.toString() || '',
      addedBarcode: '',
      barcodes: data?.product?.barcodes?.map((item) => item.barcode) || [],
      url: data?.product?.url || '',
      productPriceTags:
        data?.product?.productPriceTags.length !== 0
          ? data?.product?.productPriceTags
          : [],
      translates: data?.product?.translates,
      photos: data?.product?.photos || [],
      rozetkaProduct: rozetkaData?.rozetkaProduct
        ? { ...rozetkaData?.rozetkaProduct }
        : initRozetkaProduct,
    },
  });

  const initRozetkaProduct = {
    categoryId: '',
    categoryName: '',
    description: '',
    descriptionUa: '',
    internalId: '',
    name: '',
    nameUa: '',
    oldPrice: '',
    params: [],
    pictures: [],
    price: '',
    sku: '',
    vendor: '',
    categories: [{ id: '', name: '' }],
  };

  useEffect(() => {
    methods.reset({
      name: data?.product?.name || '',
      sku: data?.product?.sku || '',
      weight: data?.product?.weight?.toString() || '',
      width: data?.product?.width?.toString() || '',
      height: data?.product?.height?.toString() || '',
      length: data?.product?.length?.toString() || '',
      price: data?.product?.price?.toString() || '',
      priceCurrencyId: data?.product?.priceCurrencyId?.toString() || '',
      MSRprice: data?.product?.MSRprice?.toString() || '',
      MSRpriceCurrencyId: data?.product?.MSRpriceCurrencyId?.toString() || '',
      addedBarcode: '',
      barcodes: data?.product?.barcodes?.map((item) => item.barcode) || [],
      url: data?.product?.url || '',
      productPriceTags:
        data?.product?.productPriceTags.length !== 0
          ? data?.product?.productPriceTags
          : [],
      translates: data?.product?.translates,
      photos: data?.product?.photos,
      rozetkaProduct: rozetkaData?.rozetkaProduct
        ? {
            ...rozetkaData?.rozetkaProduct,
            ...(!rozetkaData?.rozetkaProduct?.categoryId && {
              categories: [{ value: '', label: '' }],
            }),
          }
        : initRozetkaProduct,
    });

    setTimeout(() => {
      methods.reset({
        ...methods.getValues(),
      });
    }, 300);
  }, [data, methods.reset, params?.id, rozetkaData]);

  const handleFormSubmit = (values) => {
    if (currentIndex === 0) {
      addProduct({
        variables: {
          input: {
            id: params.id ? +params.id : undefined,
            name: values.name,
            sku: values.sku,
            weight: Number(values.weight),
            width: Number(values.width),
            height: Number(values.height),
            length: Number(values.length),
            price: Number(values.price),
            MSRprice: Number(values.MSRprice),
            priceCurrencyId: Number(values.priceCurrencyId),
            MSRpriceCurrencyId: Number(values.MSRpriceCurrencyId),
            barcodes: values.barcodes?.map((code) => Number(code)),
            url: values.url,
            productPriceTags: values?.productPriceTags?.map(({ id, name }) => ({
              id,
              name,
            })),
            productTranslates: values?.translates?.map((translate) => ({
              id: translate.id,
              description: translate.description,
              name: translate.name,
              productLocaleId: Number(translate.productLocaleId),
            })),
          },
        },
      });
    } else if (!rozetkaData) {
      addRozetkaProduct({
        input: {
          categoryId:
            values.rozetkaProduct.categories[values.rozetkaProduct.categories.length - 1]
              ?.value,
          categoryName:
            values.rozetkaProduct.categories[values.rozetkaProduct.categories.length - 1]
              ?.label,
          description: values.rozetkaProduct.description,
          descriptionUa: values.rozetkaProduct.descriptionUa,
          internalId: Number(params.id),
          name: values.rozetkaProduct.name,
          nameUa: values.rozetkaProduct.nameUa,
          oldPrice: Number(values.MSRprice),
          params: values.rozetkaProduct.params.map((param) => {
            delete param.isValueId;
            return {
              ...param,
              id: Number(param.value),
            };
          }),
          pictures: values.photos.map((photo) => ({
            order: photo.order,
            url: photo.path,
          })),

          price: Number(values.price),
          sku: values.sku,
          vendor: values.rozetkaProduct.vendor,
        },
      });
    } else {
      updateRozetkaProduct({
        variables: {
          input: {
            categoryId:
              values.rozetkaProduct.categories[
                values.rozetkaProduct.categories.length - 1
              ]?.value,
            categoryName:
              values.rozetkaProduct.categories[
                values.rozetkaProduct.categories.length - 1
              ]?.label,
            description: values.rozetkaProduct.description,
            descriptionUa: values.rozetkaProduct.descriptionUa,
            internalId: Number(params.id),
            name: values.rozetkaProduct.name,
            nameUa: values.rozetkaProduct.nameUa,
            oldPrice: Number(values.MSRprice),
            params: values.rozetkaProduct.params.map((param) => {
              delete param.isValueId;
              if (param.__typename) {
                delete param.__typename;
              }
              return {
                ...param,
                id: Number(param.id),
              };
            }),
            pictures: values?.photos?.map((photo) => ({
              order: photo.order,
              url: photo.path,
            })),

            price: Number(values.price),
            sku: values.sku,
            vendor: values.rozetkaProduct.vendor.toString(),
          },
        },
      });
    }
  };

  const values = methods.watch();

  return (
    <RequestHandler
      loading={productQueryProps.loading || rozetkaProductQueryProps.loading}
      error={productQueryProps.error || rozetkaProductQueryProps.error}
      backgroundLoading={
        loading || updateRozetkaProductLoading || createRozetkaProductLoading
      }
    >
      <FormWrapper>
        <FormProvider {...methods}>
          <Layout
            onActionButtonPress={methods.handleSubmit(handleFormSubmit)}
            actionButtonType='save'
            actionButtonIsLoading={loading}
          >
            <RequestHandler {...currenciesQueryProps}>
              <TabNavigation
                onChange={(index) => {
                  setCurrentIndex(index);
                }}
                items={['app.generalInfo', 'app.rozetkaInfo']}
                disabledIndex={!rozetkaData ? 1 : -1}
                isFullWidth={false}
                initialState={currentIndex}
                mb={'24px'}
              >
                <TabNavigation.Item>
                  <FormRow>
                    <FormRow.Left>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.product' variant='heading' />
                        <Flex
                          justifyContent={'space-between'}
                          flexDirection={{ base: 'column', lg: 'row' }}
                        >
                          <TextFieldHookFrom
                            name='name'
                            label='app.title'
                            width={IS_DESKTOP_SCREEN ? '47%' : '100%'}
                          />
                          <TextFieldHookFrom
                            name='sku'
                            label='app.sku'
                            width={IS_DESKTOP_SCREEN ? '47%' : '100%'}
                          />
                        </Flex>
                      </BoxWrapper>

                      {!IS_DESKTOP_SCREEN ? (
                        <UploadImageMobile
                          photoFormats={data?.product?.photos}
                          loadingProduct={productQueryProps.loading}
                        />
                      ) : null}

                      <BoxWrapper style={stylesGlobal.form}>
                        <ProductLocales productId={params.id} />
                      </BoxWrapper>

                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.prices' variant='heading' />
                        <Flex
                          justify={'space-between'}
                          align={'center'}
                          flexDirection={{ base: 'column', lg: 'row' }}
                        >
                          <Box
                            display={'flex'}
                            width={IS_DESKTOP_SCREEN ? '47%' : '100%'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                          >
                            <TextFieldHookFrom
                              name='MSRprice'
                              label='app.MSRprice'
                              type='number'
                              width={'65%'}
                            />
                            <CurrencyIdSelect
                              name='MSRpriceCurrencyId'
                              label='app.currency'
                              currenciesData={currenciesData}
                            />
                          </Box>
                          <Box
                            display={'flex'}
                            flexDirection={'row'}
                            width={IS_DESKTOP_SCREEN ? '47%' : '100%'}
                            justifyContent={'space-between'}
                          >
                            <TextFieldHookFrom
                              name='price'
                              type='number'
                              label='app.price'
                              width={'65%'}
                            />
                            <CurrencyIdSelect
                              name='priceCurrencyId'
                              label='app.currency'
                              currenciesData={currenciesData}
                            />
                          </Box>
                        </Flex>
                        <PriceTags />
                      </BoxWrapper>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Barcodes />
                      </BoxWrapper>
                      <BoxWrapper style={stylesGlobal.form}>
                        <Typography intlId='app.dimensions' variant='heading' />
                        <Flex
                          justifyContent={'space-between'}
                          flexDirection={{ base: 'column', lg: 'row' }}
                        >
                          <TextFieldHookFrom
                            name='width'
                            label='app.widthCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextFieldHookFrom
                            name='length'
                            label='app.lengthCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextFieldHookFrom
                            name='height'
                            label='app.heightCm'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                          <TextFieldHookFrom
                            name='weight'
                            label='app.weightKg'
                            type='number'
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                        </Flex>
                        <Flex justifyContent={'flex-end'}>
                          {IS_DESKTOP_SCREEN ? (
                            <>
                              <Box width={'20%'}></Box>
                              <Box width={'20%'}></Box>
                              <Box width={'20%'}></Box>
                            </>
                          ) : (
                            <></>
                          )}
                          <TextFieldHookFrom
                            name='volumeWeight'
                            value={getVolumeWeight(
                              values.width,
                              values.height,
                              values.length,
                            )}
                            label='app.volumeWeight'
                            isReadOnly
                            width={IS_DESKTOP_SCREEN ? '20%' : '100%'}
                          />
                        </Flex>
                      </BoxWrapper>
                    </FormRow.Left>
                    <FormRow.Right>
                      {IS_DESKTOP_SCREEN ? (
                        <UploadImage
                          photos={data?.product?.photos}
                          loadingProduct={loading}
                          productId={params.id}
                        />
                      ) : null}
                    </FormRow.Right>
                  </FormRow>
                  {data?.product?.availabilities.length ? (
                    <Availability
                      hasScope={hasScope}
                      availabilities={data?.product?.availabilities}
                    />
                  ) : null}
                </TabNavigation.Item>
                <TabNavigation.Item disabled={!!rozetkaData}>
                  <RozetkaInfo categoryId={rozetkaData?.rozetkaProduct?.categoryId} />
                </TabNavigation.Item>
              </TabNavigation>
            </RequestHandler>
          </Layout>
        </FormProvider>
      </FormWrapper>
    </RequestHandler>
  );
};

export default Product;
