import { Box, Flex } from 'native-base';
import React from 'react';

import { TextFieldHookFrom, Typography } from '../../../../components';
import { TextInputHookForm } from '../../../../components/TextField/TextInputHookForm';
import { styles } from './styles';

const ProductInfoLocales = () => {
  return (
    <>
      <Flex justify='space-between' alignItems='baseline'>
        <Typography intlId='app.titleAndDescription' variant='heading' />
      </Flex>
      <Box style={styles.wrapper}>
        <Box>
          <Flex justify='space-between' alignItems='center' mb={4}>
            <Typography intlId='app.russian' />
          </Flex>
          <TextFieldHookFrom
            name={`rozetkaProduct.name`}
            label='app.name'
            type='text'
            width={'100%'}
          />
          <TextInputHookForm
            name={`rozetkaProduct.description`}
            label='app.specification'
            type='text'
            width={'100%'}
          />
        </Box>
        <Box>
          <Flex justify='space-between' alignItems='center' mb={4}>
            <Typography intlId='app.ukrainian' />
          </Flex>

          <TextFieldHookFrom
            name={`rozetkaProduct.nameUa`}
            label='app.name'
            type='text'
            width={'100%'}
          />
          <TextInputHookForm
            name={`rozetkaProduct.descriptionUa`}
            label='app.specification'
            type='text'
            width={'100%'}
          />
        </Box>
      </Box>
    </>
  );
};

export default ProductInfoLocales;
