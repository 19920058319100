import { gql } from '@apollo/client';

export const FRAGMENT_STOCK_PLACE = gql`
  fragment FragmentStockPlace on StockPlace {
    id
    name
    archival
    location {
      id
      name
      parentId
      typeId
    }
    availabilities {
      id
      quantity
      reservedQuantity
      condition {
        id
        name
        describe
      }
      purchase {
        id
        price
        deliveryPrice
        purchaseTime
        quantity
        product {
          id
          name
          url
          sku
          price
          quantity
          photos {
            id
            path
            user {
              id
            }
            formats(formats: [small, normal, big, smallCrop]) {
              id
              format
              pathWithTime
            }
          }
        }
      }
    }
  }
`;

export const FRAGMENT_PRODUCT = gql`
  fragment FragmentProduct on Product {
    id
    name
    url

    sku
    weight
    width
    height
    length
    price
    priceCurrencyId
    MSRprice
    MSRpriceCurrencyId
    quantity
    availabilities @include(if: $hasAccess) {
      id
      quantity
      condition {
        id
        name
      }
      purchase {
        id
        price
        deliveryPrice
        purchaseTime
        quantity
      }
      place {
        id
        name
        location {
          id
          name
          parentId
          typeId
        }
      }
    }
    barcodes {
      barcode
    }
    photos {
      id
      alt
      path
      user {
        id
      }
      formats(formats: [small, normal, big, smallCrop]) {
        id
        format
        pathWithTime
      }
      order
    }
    availableConditions {
      id
      name
      isDefault
    }
    productPriceTags {
      id
      name
    }
    translates {
      description
      id
      name
      productId
      productLocale {
        code
        id
        title
      }
      productLocaleId
    }
  }
`;
