import { Box, Flex } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Typography } from '../../../../components';
import { SelectWithSearchHookForm } from '../../../../components/SelectWithSearch/SelectWithSearchHookForm';
import { DATA_REQUEST_LIMIT } from '../../../../constants';
import { useDebounce, useGetRozetkaCategories } from '../../../../hooks';
import { transformSelectOptions } from '../../../../utils';
import { styles } from './styles';

const Category = () => {
  const { watch, control } = useFormContext();
  const { fields, append, replace, update } = useFieldArray({
    control,
    name: 'rozetkaProduct.categories',
  });

  const valueProperty = 'id';
  const labelProperty = 'name';
  const initParentId = watch('rozetkaProduct.categoryId');
  const initParentName = watch('rozetkaProduct.categoryName');
  const categories = watch('rozetkaProduct.categories');

  const [parentIds, setParentIds] = useState([initParentId || 0]);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const { debouncedValue: debouncedSearchValue, isDebouncing } = useDebounce(searchValue);

  const [lazyQueryRequest, { loading, fetchMore }] = useGetRozetkaCategories({
    fetchPolicy: 'cache-first',
  });

  const fetchMoreData = (index) => async () => {
    const { data } = await fetchMore({
      variables: {
        params: {
          search: debouncedSearchValue,
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
          parentId: parentIds[index],
        },
      },
    });
    const result = data.getRozetkaCategories;

    setOptions((prevState) => {
      const updatedResult = [...prevState];
      updatedResult[index] = result;
      return [...updatedResult];
    });
  };

  const fetchQueryResponse = (index) => async () => {
    if (parentIds[index] && parentIds[index] !== 0) return;
    await lazyQueryRequest({
      variables: {
        params: {
          search: debouncedSearchValue,
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
          parentId: parentIds[index],
        },
      },
      onCompleted: (response) => {
        const result = response?.getRozetkaCategories;
        setOptions((prevState) => {
          const updatedResult = [...prevState];
          updatedResult[index] = result;
          return [...updatedResult];
        });
      },
    });
  };

  useEffect(() => {
    if (!initParentId) return;
    lazyQueryRequest({
      variables: {
        params: {
          search: initParentName,
          offset: 0,
          limit: DATA_REQUEST_LIMIT,
        },
      },
      onCompleted: (response) => {
        const result = response?.getRozetkaCategories;
        const parentId = result?.[0]?.parentId;
        replace({ value: initParentId, label: initParentName });
        lazyQueryRequest({
          variables: {
            params: {
              search: debouncedSearchValue,
              offset: 0,
              limit: DATA_REQUEST_LIMIT,
              parentId: result[0].parentId,
            },
          },

          onCompleted: (response) => {
            setParentIds([parentId]);
            const result = response?.getRozetkaCategories;
            setOptions((prevState) => {
              const updatedResult = [...prevState];
              updatedResult[0] = result;
              return [...updatedResult];
            });
          },
        });
      },
    });
  }, [initParentId]);

  const onChangeCategorySelect = (index) => async (option) => {
    if (!option && !index) {
      setParentIds([0]);
      replace([{ value: '', label: '' }]);
      await lazyQueryRequest({
        variables: {
          params: {
            search: debouncedSearchValue,
            offset: 0,
            limit: DATA_REQUEST_LIMIT,
            parentId: 0,
          },
        },
        onCompleted: (response) => {
          const result = response?.getRozetkaCategories;
          setOptions([[...result]]);
        },
      });
    } else if (!option && fields.length - 1 > index) {
      replace(fields.slice(0, index));
      append({ value: '', label: '' });
      setParentIds((prev) => {
        return prev.slice(0, index + 1);
      });
      setOptions((prev) => prev.slice(0, index + 1));
    } else {
      await lazyQueryRequest({
        variables: {
          params: {
            search: debouncedSearchValue,
            offset: 0,
            limit: DATA_REQUEST_LIMIT,
            parentId: option.value,
          },
        },
        onCompleted: (response) => {
          const result = response?.getRozetkaCategories;
          if (result.length) {
            setParentIds((prev) => {
              prev[index + 1] = option.value;
              return prev;
            });
            if (categories.length - index === 1) {
              append({ value: '', label: '' });
            }
            if (categories.length - index > 1) {
              replace(fields.slice(0, index + 1));
              append({ value: '', label: '' });
              setParentIds((prev) => {
                return prev.slice(0, index + 2);
              });

              setOptions((prev) => prev.slice(0, index + 1));
            }
            setOptions((prevState) => {
              const updatedResult = [...prevState];
              updatedResult[index + 1] = result;
              return [...updatedResult];
            });
          }
          update(index, { value: option.value, label: option.label });
        },
      });
    }
  };

  return (
    <>
      <Flex justify='space-between' alignItems='baseline'>
        <Typography intlId='app.category' variant='heading' />
      </Flex>
      <Box style={styles.wrapper}>
        {fields?.map((field, index) => (
          <SelectWithSearchHookForm
            key={field.id}
            width={'100%'}
            label='app.category'
            onMenuOpen={fetchQueryResponse(index)}
            onMenuScrollToBottom={fetchMoreData(index)}
            onChange={onChangeCategorySelect(index)}
            placeholderIntlId='app.enterValue'
            setSearchValue={setSearchValue}
            options={transformSelectOptions({
              data: options[index],
              value: valueProperty,
              label: labelProperty,
            })}
            isLoading={loading || isDebouncing}
            name={`rozetkaProduct.categories.${index}.value`}
            mb={0}
            pr={0}
            pl={0}
            isValChange
          />
        ))}
      </Box>
    </>
  );
};

export default Category;
