import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Text, View } from 'react-native';
import { Title } from 'react-native-paper';

import { AddProductModal, Button, DesktopTable } from '../../../../components';
import { IS_DESKTOP_SCREEN } from '../../../../constants';
import stylesGlobal from '../../../../globalStyles';
import useParams from '../../../../hooks/useParams';
import styles from '../Style';
import OrderItem from './OrderItem';
import OrderItemMobile from './OrderItemMobile';

const countTotalPrice = (orderItems = []) => {
  return orderItems
    .reduce((sum, { pricePerPcs, quantity }) => sum + pricePerPcs * quantity, 0)
    .toFixed(2);
};

const OrderItems = memo(({ statuses = [] }) => {
  const params = useParams();
  const id = params?.id;

  const intl = useIntl();
  const { register, control, watch, setValue } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'orderItems',
    keyName: 'itemId',
  });

  const status = watch('status');
  const source = watch('source');
  const orderItems = watch('orderItems');
  const isReservationCanceled = watch('isReservationCanceled');

  const { reservationType } = statuses.find(({ id }) => +id === +status) || {};

  const showButton = id && reservationType === 'sent';
  const isStatusReserved =
    reservationType &&
    (reservationType === 'reserved' || reservationType === 'sent') &&
    !isReservationCanceled;

  const hasItemConditions = reservationType && reservationType !== 'none';

  const handleAddProduct = useCallback((product) => {
    append({
      pricePerPcs: (product?.source?.price).toFixed(2) || '',
      quantity: 1,
      product,
      availability: undefined,
    });
  }, []);

  const totalPrice = countTotalPrice(orderItems);

  useEffect(() => {
    setTimeout(() => {
      if (!hasItemConditions) {
        fields.forEach((val, index) => {
          val.availability = undefined;

          update(index, val);
        });
      }
    });
  }, [hasItemConditions, fields.length]);

  useEffect(() => {
    setValue(
      'isReservationCanceled',
      id ? !(reservationType === 'reserved' || reservationType === 'sent') : true,
    );
  }, [reservationType, id]);

  return (
    <View>
      <View style={styles.blockHeader}>
        <View style={[styles.orderItemHeader, stylesGlobal.title]}>
          <Title>
            {intl.formatMessage({
              id: 'order.items',
            })}
          </Title>
          {!showButton && (
            <>
              {!isStatusReserved && (
                <AddProductModal
                  currencyRatio={{ id: 1 }}
                  source={source}
                  hasError={!source}
                  errorText='app.chooseSource'
                  buttonText='app.addProduct'
                  onAddProduct={handleAddProduct}
                  isMultiSelect
                  withQuantity
                  withPrice
                  addedProducts={fields.map((field) => field.product)}
                />
              )}
              {isStatusReserved && (
                <Button
                  style={styles.reservationCancelButton}
                  onPress={() => setValue('isReservationCanceled', true)}
                  intlId='app.reservationCancel'
                />
              )}
            </>
          )}
        </View>
      </View>
      {IS_DESKTOP_SCREEN ? (
        <DesktopTable editable={false}>
          <DesktopTable.Head>
            <DesktopTable.Title intlId='app.title' />
            <DesktopTable.Title intlId='app.price' />
            <DesktopTable.Title intlId='app.quantity' textAlign={'center'} />
            {hasItemConditions && (
              <DesktopTable.Title intlId='app.condition' textAlign={'center'} />
            )}
            <DesktopTable.Title intlId='app.sum' textAlign={'center'} />
            <DesktopTable.Title />
          </DesktopTable.Head>
          <DesktopTable.Body>
            {fields.map((field, i) => (
              <OrderItem
                key={field.itemId}
                index={i}
                onDelete={() => remove(i)}
                orderItem={field}
                isStatusReserved={isStatusReserved}
                hasItemConditions={hasItemConditions}
                {...register(`orderItems[${i}]`)}
              />
            ))}
          </DesktopTable.Body>
        </DesktopTable>
      ) : (
        fields.map((field, i) => (
          <OrderItemMobile
            key={field.id}
            index={i}
            onDelete={() => remove(i)}
            orderItem={field}
            isStatusReserved={isStatusReserved}
            hasItemConditions={hasItemConditions}
            {...register(`orderItems[${i}]`)}
          />
        ))
      )}
      <Text style={styles.boxTotalPrice}>
        {totalPrice} {intl.formatMessage({ id: 'currency.uah' })}
      </Text>
    </View>
  );
});

OrderItems.displayName = 'OrderItems';

OrderItems.propTypes = {
  statuses: PropTypes.array,
  currencyRatio: PropTypes.object,
};

export default OrderItems;
