import { Input } from 'native-base';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { FieldWrapper } from '../../components';
import { IS_MOBILE_SCREEN } from '../../constants';
import { getValueFromPath } from '../../utils';

export const TextFieldHookFrom = (props) => {
  const { control, formState } = useFormContext();

  const errorMessageText = getValueFromPath(formState.errors, props.name)?.message;

  const hasError = Boolean(errorMessageText);

  return (
    <TextFieldHookFormNested
      {...props}
      control={control}
      hasError={hasError}
      errorMessageText={errorMessageText}
    />
  );
};

const TextFieldHookFormNested = memo(
  ({
    hasError,
    width,
    label,
    control,
    name,
    errorMessageText,
    placeholderIntlId = 'app.enterValue',
    isReadOnly,
    value,
  }) => {
    const intl = useIntl();

    return (
      <FieldWrapper
        isInvalid={hasError}
        label={label}
        errorMessageText={errorMessageText}
        width={width || (IS_MOBILE_SCREEN ? '100%' : '50%')}
      >
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value: fieldValue } }) => {
            return (
              <Input
                isReadOnly={isReadOnly}
                value={fieldValue || value}
                onChangeText={onChange}
                placeholder={
                  intl.messages[placeholderIntlId]
                    ? intl.formatMessage({ id: placeholderIntlId })
                    : placeholderIntlId
                }
                height={'33px'}
              />
            );
          }}
        />
      </FieldWrapper>
    );
  },
);

const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholderIntlId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isReadOnly: PropTypes.bool,
  value: PropTypes.string,
};

TextFieldHookFormNested.displayName = 'TextFieldHookFormNested';
TextFieldHookFrom.propTypes = propTypes;

TextFieldHookFormNested.propTypes = {
  ...propTypes,
  hasError: PropTypes.bool,
  control: PropTypes.object,
  errorMessageText: PropTypes.string,
};
