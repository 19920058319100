import { MaterialIcons } from '@expo/vector-icons';
import { Box, Flex, Text } from 'native-base';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Button, TextFieldHookFrom, Typography } from '../../../../components';
import { SelectWithSearchHookForm } from '../../../../components/SelectWithSearch/SelectWithSearchHookForm';
import { theme } from '../../../../constants';
import { useGetParamsFromRozetka } from '../../../../hooks';
import {
  transformSelectOptions,
  transformSelectOptionsWithInfo,
} from '../../../../utils';
import { styles } from './styles';

const Properties = ({ categoryId: defaultCategoryId }) => {
  const { watch, setValue, control } = useFormContext();
  const values = watch();
  const categories = watch('rozetkaProduct.categories');
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'rozetkaProduct.params',
  });
  const categoryId = categories?.[categories?.length - 1]?.value;
  const [options, setOptions] = useState([]);
  const [propertyOptions, setPropertyOptions] = useState([]);

  const [paramsArray, setParamsArray] = useState([]);
  const [getParams, { data: paramsData, loading }] = useGetParamsFromRozetka({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!paramsData?.getParamsFromRozetka) return;
    setParamsArray(paramsData?.getParamsFromRozetka);
  }, [paramsData?.getParamsFromRozetka]);

  useEffect(() => {
    if (!categoryId) return;
    if (defaultCategoryId !== categoryId) replace([]);
  }, [categoryId]);

  useEffect(() => {
    if (paramsArray.length && categoryId && defaultCategoryId === categoryId) {
      fields.forEach((field) => {
        const filteredPropertyOptions = paramsArray.filter(
          (option) => option.name === field.name,
        );
        if (field.valueId) {
          setPropertyOptions((prev) => [...prev, [...filteredPropertyOptions]]);
        } else {
          setPropertyOptions((prev) => [...prev, []]);
        }
      });
    }
  }, [paramsArray, defaultCategoryId]);

  useEffect(() => {
    if (!paramsArray.length) return;

    const uniqueMap = new Map();
    for (const item of paramsArray) {
      if (item && item.id !== undefined) {
        uniqueMap.set(item.id, item);
      }
    }
    const filteredSetOptions = new Set(uniqueMap.values());
    const filteredOptions = Array.from(filteredSetOptions);
    const selectedParamIds = values?.rozetkaProduct?.params?.map((param) => param.id);
    const selectedFiteredOptions = filteredOptions?.filter(
      (option) => !selectedParamIds?.includes(Number(option.id)),
    );

    const transformedOptions = transformSelectOptionsWithInfo({
      data: selectedFiteredOptions,
      value: 'id',
      label: 'name',
      info: 'valueId',
    });

    setOptions(transformedOptions);
  }, [values?.rozetkaProduct.params?.length, paramsArray.length]);

  const getParamsHandler = () => {
    categoryId &&
      getParams({
        variables: {
          categoryId: categoryId,
        },
      });
  };

  useEffect(() => {
    if (categoryId && defaultCategoryId === categoryId) getParamsHandler();
  }, [categoryId]);

  const handleAddParam = () => {
    if (values.param) {
      const { value, label, info: valueId } = values.param;
      append({
        id: value,
        name: label,
        value: '',
        ...(valueId ? { isValueId: !!valueId, valueId: '' } : { isValueId: !!valueId }),
      });

      const filteredPropertyOptions = paramsArray.filter((option) => option.id === value);

      !!valueId &&
        setPropertyOptions((prev) => {
          prev[fields.length] = filteredPropertyOptions;
          return prev;
        });
      setValue('param.value', '');
    }
  };

  const handleChangeParam = (option) => {
    option && setValue('param', option);
  };

  const handleRemoveParam = (index) => () => {
    remove(index);
    setPropertyOptions((prev) => {
      prev.splice(index, 1);
      return prev;
    });
  };

  const handleChangeProperty = (index) => (option) => {
    setValue(`rozetkaProduct.params.${index}.value`, option.label);
  };

  return (
    <>
      <Flex justify='space-between' alignItems='baseline'>
        <Typography intlId='app.properties' variant='heading' />
      </Flex>
      <Flex justify='space-between' alignItems='center'>
        <SelectWithSearchHookForm
          width='80%'
          pl={0}
          pr={0}
          isLoading={loading}
          onMenuOpen={getParamsHandler}
          options={options}
          name={'param.value'}
          label='app.properties'
          onChange={handleChangeParam}
          isValChange
        />
        <Button
          intlId='app.add'
          onPress={handleAddParam}
          variant='ghost'
          isDisabled={!values?.param?.value}
        />
      </Flex>
      {!!fields.length && <Box style={styles.divider}></Box>}
      <Box style={styles.wrapper}>
        {fields.map((field, index) => (
          <Box key={field.id}>
            <Flex justify='space-between' alignItems='center' mb={4}>
              <Text style={styles.text}>{field.name}</Text>
              <MaterialIcons
                onPress={handleRemoveParam(index)}
                name='delete'
                size={25}
                color={theme.colors.primary}
              />
            </Flex>
            {!field.valueId && !field.isValueId ? (
              <TextFieldHookFrom
                name={`rozetkaProduct.params.${index}.value`}
                label={field.name}
                type='text'
                width={'100%'}
              />
            ) : (
              <SelectWithSearchHookForm
                width='80%'
                pl={0}
                pr={0}
                name={`rozetkaProduct.params.${index}.valueId`}
                label={field.name}
                options={transformSelectOptions({
                  data: propertyOptions[index],
                  label: 'valueName',
                  value: 'valueId',
                })}
                onChange={handleChangeProperty(index)}
                isLoading={loading}
                isValChange
              />
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Properties;

Properties.propTypes = {
  categoryId: PropTypes.string,
};
