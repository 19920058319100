/* eslint-disable react-native/no-raw-text */
import { Flex } from 'native-base';
import React, { useRef } from 'react';

import {
  DesktopTable,
  FormattedDate,
  Layout,
  MobileTable,
  RequestHandler,
  TransactionsAdditionalData,
  Typography,
} from '../../../components';
import { DATA_REQUEST_LIMIT, IS_DESKTOP_SCREEN } from '../../../constants';
import { useCurrencyContext, useSearchContext } from '../../../context';
import { useGetTransactionsQuery, useHistory } from '../../../hooks';
import useFilter from '../../../hooks/useFilter';
import { getTransactionAmount } from '../../../utils';
import { TransactionsFilters } from './TransactionsFilters';
import TransactionsMobileItem from './TransactionsMobileItem/TransactionsMobileItem';

const COLUMNS = [
  { intlId: 'app.date', sortId: 'date' },
  { intlId: 'app.sum' },
  { intlId: 'app.typeOfTransaction' },
  { intlId: 'app.account' },
  { intlId: 'app.additionalInformation' },
  { intlId: 'app.comment' },
];

const Transactions = () => {
  const navigation = useHistory();

  const { data, fetchMore, ...queryProps } = useGetTransactionsQuery();

  const { currentCurrency } = useCurrencyContext();

  const { filters } = useFilter();

  const { debouncedSearchValue } = useSearchContext();

  const isLoadMoreEnabled = useRef(true);

  const fetchMoreData = async (resolve, reject) => {
    if (isLoadMoreEnabled.current) {
      await fetchMore({
        variables: {
          offset: data?.transactions?.length,
          limit: DATA_REQUEST_LIMIT,
          search: debouncedSearchValue,
          order: filters?.direction || 'desc',
          orderBy: filters?.field || 'datetime',
          filters: {
            dateFrom: filters?.dateFrom || '',
            dateTo: filters?.dateTo || '',
            typeIds: filters?.typeIds,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            reject?.();
            return prev;
          }

          resolve?.();

          if (!fetchMoreResult?.transactions?.length) {
            isLoadMoreEnabled.current = false;
          }

          return {
            transactions: [...prev?.transactions, ...fetchMoreResult?.transactions],
          };
        },
      });
    }
  };

  const handleClickAddButton = () => {
    navigation.push('/bookkeeping/transaction/');
  };

  const handleEditButtonClick = (itemId) => {
    navigation.push(`/bookkeeping/transaction/${itemId}`);
  };

  return (
    <Layout
      isScrollable={false}
      filters={<TransactionsFilters />}
      onActionButtonPress={handleClickAddButton}
    >
      <RequestHandler {...queryProps}>
        {IS_DESKTOP_SCREEN ? (
          <DesktopTable fetchMoreData={fetchMoreData}>
            <DesktopTable.Head>
              {COLUMNS.map((item, index) => (
                <DesktopTable.Title key={index} {...item} />
              ))}
            </DesktopTable.Head>
            <DesktopTable.Body>
              {data?.transactions?.map((item, index) => (
                <DesktopTable.Row
                  key={index}
                  onEditButtonClick={() => handleEditButtonClick(item.id)}
                >
                  <DesktopTable.Cell>
                    <FormattedDate date={item?.date} />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>
                    <Typography
                      fontWeight='500'
                      color={item?.type?.type === 'income' ? 'green' : 'red'}
                    >
                      {getTransactionAmount(
                        item?.amount,
                        item?.type?.type,
                        item?.currency?.symbol,
                        currentCurrency === 'default'
                          ? {
                              currencySymbol: item?.bankAccount?.currency?.symbol,
                            }
                          : {
                              currencySymbol: currentCurrency?.symbol,
                              originalCurrencyRate: item?.currencyRate,
                              targetCurrencyRate: currentCurrency?.lastRate?.ratio,
                            },
                      )}
                    </Typography>
                  </DesktopTable.Cell>
                  <DesktopTable.Cell>{item?.type?.name}</DesktopTable.Cell>
                  <DesktopTable.Cell>{item?.bankAccount?.title}</DesktopTable.Cell>
                  <DesktopTable.Cell maxWidth='70px'>
                    <TransactionsAdditionalData item={item} />
                  </DesktopTable.Cell>
                  <DesktopTable.Cell maxWidth='350px' width={'350px'}>
                    <Flex>
                      <Typography isTruncated>{item.comment}</Typography>
                    </Flex>
                  </DesktopTable.Cell>
                </DesktopTable.Row>
              ))}
            </DesktopTable.Body>
          </DesktopTable>
        ) : (
          <MobileTable
            fetchMoreData={fetchMoreData}
            data={data?.transactions}
            renderItem={(item) => (
              <TransactionsMobileItem
                item={item}
                onItemPress={() => handleEditButtonClick(item?.id)}
                date={
                  <FormattedDate
                    fontSize='14px'
                    fontWeight='600'
                    color='#828282'
                    date={new Date(item?.date)}
                  />
                }
                typeName={item?.type?.name}
                amount={
                  <Typography
                    fontSize='19px'
                    fontWeight='700'
                    color={item?.type?.type === 'income' ? 'green' : 'red'}
                  >
                    {getTransactionAmount(
                      item?.amount,
                      item?.type?.type,
                      item?.currency?.symbol,
                      currentCurrency === 'default'
                        ? {
                            currencySymbol: item?.bankAccount?.currency?.symbol,
                          }
                        : {
                            currencySymbol: currentCurrency?.symbol,
                            originalCurrencyRate: item?.currencyRate,
                            targetCurrencyRate: currentCurrency?.lastRate?.ratio,
                          },
                    )}
                  </Typography>
                }
                comment={item?.comment}
              />
            )}
          />
        )}
      </RequestHandler>
    </Layout>
  );
};

export default Transactions;
