import { gql } from '@apollo/client';

import { FRAGMENT_PRODUCT, FRAGMENT_STOCK_PLACE } from './fragments';

export const GET_ORDERS = gql`
  query orders(
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $search: String
    $filters: OrderFilterInput!
  ) {
    orders(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      search: $search
      filters: $filters
    ) {
      id
      createdAt
      user {
        id
        firstName
        lastName
      }
      customer {
        id
        firstName
        lastName
      }
      source {
        id
        name
      }
      status {
        id
        name
      }
      paymentStatus {
        id
        name
      }
      orderItems {
        id
        pricePerPcs
        quantity
      }
      discount
    }
  }
`;

export const GET_ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      createdAt
      user {
        id
        firstName
        lastName
      }
      customer {
        id
        firstName
        lastName
        middleName
        emails {
          id
          email
        }
        phones {
          id
          phone
        }
      }
      source {
        id
        name
      }
      status {
        id
        name
        reservationType
      }
      orderItems {
        id
        pricePerPcs
        quantity
        product {
          id
          name
          url
          sku
          availableConditions {
            id
            name
            isDefault
          }
        }
        availability {
          allowedConditions {
            id
            name
            isDefault
          }
          condition {
            id
            name
          }
        }

        place {
          id
          name
          location {
            id
            name
            parentId
            typeId
          }
        }
      }
      discount
      deliveryInformation {
        id
        address
        comment
        ttn
        city {
          id
          name
          region {
            id
            name
            country {
              id
              name
            }
          }
        }
      }
      novaPoshtaShipping {
        orderId
        city {
          ref
          description
        }
        warehouse {
          ref
          description
        }
        addressRef
        cityRef
        payerType
        serviceType
        street
        streetRef
        buildingNumber
        flatNumber
      }
      ukrposhtaShipping {
        orderId
        cityId
        cityName
        cityFullName
        postcode
        deliveryType
        paidByRecipient
        warehouseId
        warehouseName
        street
        houseNumber
        apartmentNumber
      }
      shippingType {
        id
        name
      }
      paymentType {
        id
        name
      }
      paymentStatus {
        id
        name
      }
      callbackOff
      customerComment
      managerComment
    }
    statuses {
      id
      name
      reservationType
    }
    users {
      id
      firstName
      lastName
    }
    sources {
      id
      name
    }
    cities {
      id
      name
      region {
        id
        name
        country {
          id
          name
        }
      }
    }
    shippingTypes {
      id
      name
    }
    paymentTypes {
      id
      name
    }
    paymentStatuses {
      id
      name
    }
    customers {
      id
      firstName
      lastName
      middleName
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
    }
  }
`;

export const GET_ORDER_FIELDS = gql`
  {
    statuses {
      id
      name
      reservationType
    }
    users {
      id
      firstName
      lastName
    }
    sources {
      id
      name
    }
    cities {
      id
      name
      region {
        id
        name
        country {
          id
          name
        }
      }
    }
    shippingTypes {
      id
      name
    }
    paymentTypes {
      id
      name
    }
    paymentStatuses {
      id
      name
    }
    customers {
      id
      firstName
      lastName
      middleName
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
    }
  }
`;

export const GET_ORDER_ITEMS = gql`
  ${FRAGMENT_PRODUCT}
  query orderItems($ids: [ID], $source: ID!, $hasAccess: Boolean = true) {
    orderItems(ids: $ids) {
      id
      quantity
      pricePerPcs
      product {
        ...FragmentProduct
        source(id: $source) {
          price
        }
      }
    }
  }
`;

export const CUSTOMERS_FILTER = gql`
  {
    customers {
      id
      firstName
      lastName
      createdAt
    }
    phones {
      id
      phone
    }
    emails {
      id
      email
    }
  }
`;

export const HAS_SCOPES_QUERY = gql`
  query hasScopes($scopes: [String]!) {
    hasScopes(scopes: $scopes) {
      isAllowed
      scope
    }
  }
`;

export const ORDERS_FILTER = gql`
  {
    statuses {
      id
      name
    }
    users {
      id
      firstName
      lastName
    }
    customers {
      id
      firstName
      lastName
      phones {
        id
        phone
      }
    }
    sources {
      id
      name
    }
    paymentStatuses {
      id
      name
    }
  }
`;

export const GET_CURRENT_USER = gql`
  {
    currentUser {
      avatar
      firstName
      lastName
      roles {
        name
      }
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query customers(
    $search: String
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $filters: CustomerFilterInput
  ) {
    customers(
      search: $search
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      filters: $filters
    ) {
      id
      firstName
      lastName
      createdAt
      phones {
        id
        phone
      }
      emails {
        id
        email
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      firstName
      middleName
      lastName
      middleName
      createdAt
      birthday
      sex
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
      children {
        id
        name
        birthday
        sex
        relationType {
          id
          name
        }
      }
      orders {
        id
        createdAt
        source {
          id
          name
        }
        status {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
        paymentStatus {
          id
          name
        }
        orderItems {
          id
          pricePerPcs
          quantity
        }
        discount
      }
    }
    relationTypes {
      id
      name
    }
  }
`;
export const GET_CUSTOMER_OPTION = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      id
      firstName
      middleName
      lastName
      middleName
      emails {
        id
        email
      }
      phones {
        id
        phone
      }
    }
  }
`;

export const GET_CUSTOMER_FIELDS = gql`
  {
    relationTypes {
      id
      name
    }
  }
`;

export const GET_STOCK_LOCATIONS = gql`
  query stockLocations($offset: Int, $limit: Int, $ids: [ID]) {
    stockLocations(offset: $offset, limit: $limit, ids: $ids) {
      id
      name
      parentId
      typeId
    }
  }
`;

export const GET_PURCHASE = gql`
  query purchase($id: ID!) {
    purchase(id: $id) {
      id
      price
      deliveryPrice
      purchaseTime
      quantity
      product {
        id
        name
        price
        weight
        width
        height
        length
      }
      parcel {
        id
        trackingCode
      }
      forwarding {
        id
        trackingCode
      }
    }
  }
`;

export const GET_STOCK_PLACES = gql`
  ${FRAGMENT_STOCK_PLACE}
  query stockPlaces(
    $search: String
    $archival: Boolean
    $locationIds: [ID]
    $offset: Int
    $limit: Int
    $sort: Sort
  ) {
    stockPlaces(
      search: $search
      archival: $archival
      locationIds: $locationIds
      offset: $offset
      limit: $limit
      sort: $sort
    ) {
      ...FragmentStockPlace
    }
  }
`;

export const GET_STOCK_PLACE = gql`
  ${FRAGMENT_STOCK_PLACE}
  query stockPlace($id: ID!) {
    stockPlace(id: $id) {
      ...FragmentStockPlace
      availabilities {
        quantity
        reservedQuantity
        purchase {
          id
          price
        }
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  ${FRAGMENT_PRODUCT}
  query products(
    $ids: [ID]
    $search: String
    $limit: Int
    $offset: Int
    $hasAccess: Boolean = false
  ) {
    products(ids: $ids, search: $search, limit: $limit, offset: $offset) {
      ...FragmentProduct
    }
  }
`;

export const FIND_PRODUCTS = gql`
  ${FRAGMENT_PRODUCT}
  query products($search: String, $source: ID!, $hasAccess: Boolean = true) {
    products(search: $search) {
      ...FragmentProduct
      source(id: $source) {
        price
        availability
      }
    }
  }
`;

export const GET_USERS = gql`
  query filteredUsers($offset: Int, $limit: Int, $search: String) {
    filteredUsers(offset: $offset, limit: $limit, search: $search) {
      id
      firstName
      lastName
      createdAt
      phone
      email
      avatar
      roles {
        id
        name
      }
    }
  }
`;

export const GET_AVAILABILITY = gql`
  query availability($filters: AvailabilityFilterInput) {
    availability(filters: $filters) {
      quantity
      condition {
        id
        name
        describe
      }
      place {
        id
        archival
        name
        location {
          id
          name
        }
      }
      purchase {
        id
        price
        deliveryPrice
        purchaseTime
        quantity
        product {
          id
          name
          url
          sku
          price
          quantity
        }
      }
    }
  }
`;

export const LOGIN_QUERY = gql`
  query loginUser($email: String, $password: String) {
    loginUser(email: $email, password: $password) {
      token
    }
  }
`;

export const PURCHASES_QUERY = gql`
  query purchases(
    $offset: Int
    $parcelId: Int
    $forwardingId: Int
    $limit: Int
    $search: String
    $forwardingIdViaParcel: Int
  ) {
    purchases(
      offset: $offset
      parcelId: $parcelId
      forwardingId: $forwardingId
      limit: $limit
      search: $search
      forwardingIdViaParcel: $forwardingIdViaParcel
    ) {
      id
      product {
        id
        name
        price
        sku
        photos {
          path
        }
        weight
        width
        height
        length
      }
      parcel {
        id
        trackingCode
        status {
          id
          name
        }
      }
      forwarding {
        id
        trackingCode
        deliveryPrice
        status {
          id
          name
        }
      }
      price
      deliveryPrice
      purchaseTime
      quantity
    }
  }
`;

export const FIND_PURCHASE_PARSELS_QUERY = gql`
  query findPurchaseParcels($offset: Int!, $limit: Int!, $search: String) {
    purchaseParcels(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        trackingCode
      }
    }
  }
`;

export const FIND_PURCHASE_FORWARDINGS_QUERY = gql`
  query findPurchaseForwardings($offset: Int!, $limit: Int!, $search: String) {
    purchaseForwardings(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        trackingCode
        date
      }
    }
  }
`;

export const GET_USER_QUERY = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      middleName
      lastName
      middleName
      createdAt
      birthday
      sex
      status
      email
      phone
      skype
      roles {
        id
        name
      }
    }
  }
`;

export const GET_ROLES_QUERY = gql`
  query {
    userRoles {
      id
      name
    }
  }
`;

export const GET_PRODUCT_QUERY = gql`
  ${FRAGMENT_PRODUCT}
  query product($id: ID, $barcode: BigInt, $hasAccess: Boolean = true) {
    product(id: $id, barcode: $barcode) {
      ...FragmentProduct
    }
  }
`;

export const GET_SOURCES_QUERY = gql`
  query {
    sources {
      id
      name
      key
    }
  }
`;

export const GET_PURCHASE_PARCEL_STATUSES_QUERY = gql`
  query {
    purchaseParcelStatuses {
      id
      name
    }
  }
`;

export const GET_PURCHASE_FORWARDING_STATUSES_QUERY = gql`
  query {
    purchaseForwardingStatuses {
      id
      name
    }
  }
`;

export const GET_PURCHASE_SOURCES_QUERY = gql`
  query {
    purchaseSources {
      id
      isInternational
      link
      name
    }
  }
`;

export const GET_PURCHASE_SOURCE_QUERY = gql`
  query PurchaseSource($purchaseSourceId: ID!) {
    purchaseSource(id: $purchaseSourceId) {
      isInternational
      link
      name
      id
    }
  }
`;

export const GET_PURCHASE_FORWARDINGS_QUERY = gql`
  query getPurchaseForwardings($offset: Int!, $limit: Int!, $search: String) {
    purchaseForwardings(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        date
        trackingCode
        deliveryPrice
        status {
          id
          name
        }
        purchases {
          id
          quantity
          product {
            id
            length
            width
            height
            weight
          }
        }
      }
    }
  }
`;

export const GET_PURCHASE_FORWARDING_PARCELS_QUERY = gql`
  ${FRAGMENT_PRODUCT}
  query purchaseParcels(
    $offset: Int!
    $limit: Int!
    $search: String
    $ids: [ID]
    $source: ID!
    $hasAccess: Boolean = true
  ) {
    purchaseParcels(offset: $offset, limit: $limit, search: $search, ids: $ids) {
      count
      rows {
        id
        trackingCode
        date
        status {
          id
          name
        }
        source {
          id
          name
        }
        purchases {
          id
          quantity
          product {
            ...FragmentProduct
            source(id: $source) {
              price
              availability
            }
          }
        }
      }
    }
  }
`;

export const GET_PURCHASE_PARCELS_QUERY = gql`
  query purchaseParcels($offset: Int!, $limit: Int!, $search: String) {
    purchaseParcels(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        trackingCode
        date
        status {
          id
          name
        }
        source {
          id
          name
        }
        purchases {
          id
          quantity
          product {
            id
            length
            width
            height
            weight
          }
        }
      }
    }
  }
`;

export const GET_PURCHASE_PARCEL_QUERY = gql`
  query purchaseParcel($id: ID!) {
    purchaseParcel(id: $id) {
      id
      trackingCode
      date
      forwardings {
        id
        trackingCode
      }
      source {
        id
        name
      }
      status {
        id
        name
      }
      purchases {
        id
        price
        quantity
        product {
          id
          name
        }
        forwarding {
          id
          trackingCode
        }
      }
    }
  }
`;

export const GET_PURCHASE_FORWARDING_QUERY = gql`
  query purchaseForwarding($id: ID!) {
    purchaseForwarding(id: $id) {
      id
      trackingCode
      date
      deliveryPrice
      parcels {
        id
        trackingCode
      }
      status {
        id
        name
      }
      purchases {
        id
        price
        quantity
        deliveryPrice
        parcel {
          id
          trackingCode
        }
        product {
          id
          weight
          name
        }
      }
    }
  }
`;

export const GET_CURRENCIES_QUERY = gql`
  query currencies {
    currencies {
      id
      name
      code
      symbol
      currencyRates {
        id
        ratio
        date
        dateTo
        currencyId
      }
      lastRate {
        id
        ratio
        date
        dateTo
        currencyId
      }
    }
  }
`;

export const GET_CURRENCY_QUERY = gql`
  query currency($id: ID!) {
    currency(id: $id) {
      id
      name
      code
      symbol
      currencyRates {
        id
        ratio
        date
        dateTo
        currencyId
      }
      lastRate {
        id
        ratio
        date
        dateTo
        currencyId
      }
    }
  }
`;

export const GET_CURRENCY_RATIO_QUERY = gql`
  query currency($id: ID!) {
    currency(id: $id) {
      id
      lastRate {
        ratio
      }
    }
  }
`;

export const GET_EXCHANGE_QUERY = gql`
  query exchange($id: ID!) {
    exchange(id: $id) {
      id
      date
      fromAmount
      fromBankAccountId
      toAmount
      toBankAccountId
      comment
      fromBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
      toBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
    }
  }
`;

export const GET_EXCHANGES_QUERY = gql`
  query exchanges(
    $offset: Int
    $limit: Int
    $order: String
    $search: String
    $filters: ExchangeFilterInput
  ) {
    exchanges(
      offset: $offset
      limit: $limit
      order: $order
      search: $search
      filters: $filters
    ) {
      id
      date
      fromAmount
      fromBankAccountId
      toAmount
      toBankAccountId
      comment
      fromBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
      toBankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
      }
    }
  }
`;

export const GET_REPORTS_QUERY = gql`
  query reports($filters: ReportFilterInput) {
    reports(filters: $filters) {
      income
      exchange
      spend
      currencyRate
      bankAccount {
        id
        title
        description
        currencyId
        currency {
          id
          name
          code
          symbol
          lastRate {
            id
            ratio
            date
            dateTo
            currencyId
          }
        }
        isArchived
        initialBalance
      }
    }
  }
`;

export const GET_TRANSACTIONS_QUERY = gql`
  query transactions(
    $offset: Int
    $limit: Int
    $order: String
    $search: String
    $filters: TransactionFilterInput
  ) {
    transactions(
      offset: $offset
      limit: $limit
      order: $order
      search: $search
      filters: $filters
    ) {
      id
      typeId
      date
      amount
      comment
      type {
        id
        name
        type
      }
      bankAccount {
        id
        title
        description
        currencyId
        isArchived
        currency {
          id
          symbol
          currencyRates {
            ratio
            date
            dateTo
            currencyId
          }
          lastRate {
            ratio
            date
            dateTo
            currencyId
          }
        }
      }

      order {
        id
        createdAt
      }
      user {
        id
        firstName
        lastName
      }
      forwarding {
        id
        trackingCode
      }
      purchase {
        id
        purchaseTime
      }
    }
  }
`;

export const GET_TRANSACTION_QUERY = gql`
  query transaction($id: ID!) {
    transaction(id: $id) {
      id
      typeId
      date
      amount
      comment
      type {
        id
        name
        type
      }
      bankAccount {
        id
        title
        description
        currencyId
        isArchived
        currencyId
        currency {
          id
          symbol
          currencyRates {
            ratio
            date
            dateTo
            currencyId
          }
          lastRate {
            ratio
            date
            dateTo
            currencyId
          }
        }
      }
      order {
        id
        createdAt
        user {
          id
          firstName
          lastName
        }
        customer {
          id
          firstName
          lastName
        }
        source {
          id
          name
        }
        status {
          id
          name
        }
        paymentStatus {
          id
          name
        }
        orderItems {
          id
          pricePerPcs
          quantity
        }
        discount
      }
      user {
        id
        firstName
        lastName
      }
      forwarding {
        id
        trackingCode
      }
      purchase {
        id
        price
        deliveryPrice
        purchaseTime
        quantity
        product {
          id
          name
          price
          weight
          width
          height
          length
        }
        parcel {
          id
          trackingCode
          status {
            id
            name
          }
        }
        forwarding {
          id
          trackingCode
          deliveryPrice
          status {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_TRANSACTIONS_TYPES_QUERY = gql`
  query {
    transactionTypes {
      id
      name
    }
  }
`;

export const GET_CONDITIONS_QUERY = gql`
  query {
    conditions {
      id
      name
    }
  }
`;

export const GET_PACKAGING_TEMPLATES = gql`
  query packagingTemplates($search: String) {
    packagingTemplates(search: $search) {
      id
      name
      width
      length
      height
      weight
    }
  }
`;

export const GET_PACKAGING_TEMPLATE = gql`
  query packagingTemplate($id: Int!) {
    packagingTemplate(id: $id) {
      id
      name
      width
      length

      height
      weight
    }
  }
`;

export const GET_CITY = gql`
  query city($id: ID!) {
    city(id: $id) {
      id
      name
      region {
        id
        name
      }
    }
  }
`;

export const FIND_CITIES = gql`
  query findCities($search: String) {
    findCities(search: $search) {
      id
      name
      region {
        id
        name
      }
    }
  }
`;

export const GET_WAREHOUSES_NP = gql`
  query GetWarehousesNovaPoshta(
    $offset: Int!
    $limit: Int!
    $cityRef: UUID
    $warehouseRef: UUID
    $search: String
  ) {
    getWarehousesNovaPoshta(
      offset: $offset
      limit: $limit
      cityRef: $cityRef
      warehouseRef: $warehouseRef
      search: $search
    ) {
      ref
      description
    }
  }
`;

export const GET_CITIES_NP = gql`
  query GetCitiesNovaPoshta($cityRef: UUID, $cityName: String) {
    getCitiesNovaPoshta(cityRef: $cityRef, cityName: $cityName) {
      ref
      description
    }
  }
`;

export const GET_CITIES_UKR_POSHTA = gql`
  query GetCitiesUkrposhta($search: String!) {
    getCitiesUkrposhta(search: $search) {
      id
      name
      fullName
      regionId
    }
  }
`;
export const GET_WAREHOUSES_UKR_POSHTA = gql`
  query GetWarehousesUkrposhta($cityId: Int!) {
    getWarehousesUkrposhta(cityId: $cityId) {
      id
      name
      postcode
    }
  }
`;

export const GET_STREETS_NP = gql`
  query getStreetNovaPoshta(
    $offset: Int!
    $limit: Int!
    $cityRef: UUID!
    $search: String
  ) {
    getStreetNovaPoshta(
      offset: $offset
      limit: $limit
      cityRef: $cityRef
      search: $search
    ) {
      ref
      description
      type
    }
  }
`;

export const GET_PAYERS_TYPES_NP = gql`
  query {
    getTypesOfPayersNovaPoshta {
      ref
      description
    }
  }
`;

export const GET_PHOTO_BY_ID = gql`
  query usePhotoById($photoId: ID!) {
    photo(id: $photoId) {
      id
      alt
      path
    }
  }
`;
export const GET_BANKS_ACCOUNTS = gql`
  query bankAccounts($filters: BankAccountFilterInput, $sort: Sort) {
    bankAccounts(filters: $filters, sort: $sort) {
      id
      title
      description
      currencyId
      currency {
        id
        name
        code
        symbol
        currencyRates {
          id
          ratio
          date
          dateTo
          currencyId
        }
      }
      isArchived
    }
  }
`;

export const GET_BANK_ACCOUNT = gql`
  query bankAccount($id: ID!) {
    bankAccount(id: $id) {
      id
      title
      description
      currencyId
      hasTransactions
      currency {
        id
        name
        code
        symbol
        currencyRates {
          id
          ratio
          date
          dateTo
          currencyId
        }
      }
      isArchived
      initialBalance
    }
  }
`;

export const GET_SHIPPING_TYPES = gql`
  query ShippingTypes($ids: [ID]) {
    shippingTypes(ids: $ids) {
      id
      name
    }
  }
`;

export const GET_SHIPPING_TYPE = gql`
  query ShippingType($id: ID!) {
    shippingType(id: $id) {
      id
      name
    }
  }
`;

export const GET_PAYMENT_TYPES = gql`
  query PaymentTypes($ids: [ID]) {
    paymentTypes(ids: $ids) {
      id
      name
      type
    }
  }
`;

export const GET_PAYMENT_TYPE = gql`
  query PaymentType($id: ID!) {
    paymentType(id: $id) {
      id
      name
      type
    }
  }
`;

export const GET_STATUSES = gql`
  query Statuses {
    statuses {
      id
      name
      reservationType
    }
  }
`;

export const GET_PROM_ORDER_STATUS_MAPS = gql`
  query PromOrderStatusMaps {
    promOrderStatusMaps {
      isPromStatusDefault
      isStatusDefault
      promStatusDesc
      promStatusName
      statusId
    }
  }
`;

export const GET_PROM_ORDER_STATUS_MAP = gql`
  query PromOrderStatusMap($args: PromOrderStatusMapArgs!) {
    promOrderStatusMap(args: $args) {
      isPromStatusDefault
      isStatusDefault
      promStatusDesc
      promStatusName
      statusId
    }
  }
`;

export const GET_FLEXIBLESHOP_ORDER_STATUS_MAPS = gql`
  query FlexibleshopOrderStatusMaps {
    flexibleshopOrderStatusMaps {
      flexibleshopStatusDesc
      flexibleshopStatusId
      isFlexibleshopStatusDefault
      isStatusDefault
      statusId
    }
  }
`;

export const GET_FLEXIBLESHOP_ORDER_STATUS_MAP = gql`
  query FlexibleshopOrderStatusMap($args: FlexibleshopOrderStatusMapArgs!) {
    flexibleshopOrderStatusMap(args: $args) {
      flexibleshopStatusDesc
      flexibleshopStatusId
      isFlexibleshopStatusDefault
      isStatusDefault
      statusId
    }
  }
`;

export const GET_ROZETKA_ORDER_STATUS_MAPS = gql`
  query RozetkaOrderStatusMaps {
    rozetkaOrderStatusMaps {
      isRozetkaStatusDefault
      isStatusDefault
      rozetkaStatusDesc
      rozetkaStatusId
      statusId
    }
  }
`;

export const GET_ROZETKA_ORDER_STATUS_MAP = gql`
  query RozetkaOrderStatusMap($args: RozetkaOrderStatusMapArgs!) {
    rozetkaOrderStatusMap(args: $args) {
      isRozetkaStatusDefault
      isStatusDefault
      rozetkaStatusDesc
      rozetkaStatusId
      statusId
    }
  }
`;

export const GET_STRAPI_ORDER_STATUS_MAPS = gql`
  query StrapiOrderStatusMaps {
    strapiOrderStatusMaps {
      isStatusDefault
      isStrapiStatusDefault
      statusId
      strapiStatusDesc
      strapiStatusName
    }
  }
`;

export const GET_STRAPI_ORDER_STATUS_MAP = gql`
  query StrapiOrderStatusMap($args: StrapiOrderStatusMapArgs!) {
    strapiOrderStatusMap(args: $args) {
      isStatusDefault
      isStrapiStatusDefault
      statusId
      strapiStatusDesc
      strapiStatusName
    }
  }
`;

export const GET_PRICE_TAGS = gql`
  query PriceTags($offset: Int!, $limit: Int!, $search: String) {
    priceTags(offset: $offset, limit: $limit, search: $search) {
      id
      name
    }
  }
`;

export const GET_PRODUCT_LOACALES = gql`
  query ProductLocales($search: String, $limit: Int, $offset: Int) {
    productLocales(search: $search, limit: $limit, offset: $offset) {
      code
      id
      title
    }
  }
`;

export const GET_ROZETKA_PRODUCT = gql`
  query RozetkaProduct($internalId: Int!) {
    rozetkaProduct(internalId: $internalId) {
      id
      internalId
      offerId
      name
      nameUa
      description
      descriptionUa
      url
      price
      oldPrice
      availability
      categoryId
      categoryName
      sku
      vendor
      params {
        id
        name
        value
        valueId
      }
      pictures {
        order
        url
      }
    }
  } 
`;

export const GET_PARAMS_FROM_ROZETKA= gql`
  query GetParamsFromRozetka($categoryId: Int!) {
    getParamsFromRozetka(categoryId: $categoryId) {
      id
      name
      attrType
      filterType
      unit
      isGlobal
      valueId
      valueName
    }
  }
`;

export const GET_ROZETKA_CATEGORIES = gql`
  query GetRozetkaCategories($params: RozetkaCategoriesArgs!) {
    getRozetkaCategories(params: $params) {
      id
      parentId
      name
    }
  }
`;

export const GET_ROZETKA_VENDORS = gql`
  query GetRozetkaVendors($limit: Int!, $offset: Int!, $search: String) {
    getRozetkaVendors(limit: $limit, offset: $offset, search: $search) {
      rozetkaId
      name
    }
  }
`;
